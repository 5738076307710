// Variable overrides first
$primary: #007bff;
$prefix: "mo-";

// Then import Bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";

$color-1: #007bff;
$color-2: #282c34;
.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .scrollable {
    overflow: auto;
  }
  .app-header {
    background-color: $color-2;
    min-height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: calc(10px + 2vmin);
    color: white;

    .navigationOptions {
      position: relative;
    }
  }

  .company-name {
    background-color: $color-2;
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .equipment {
    padding-top: 40px;

    .jcb {
      .picture-container {
        max-height: 70vh;
        max-width: 100%;
      }
      .picture {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
    .fact {
      .title {
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
      }
      .number {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1;
      }
      &:not(:first-of-type) {
        @include media-breakpoint-up(sm) {
          border-left-color: rgba($color-2, 0.25);
          border-left-style: solid;
          border-left-width: 2px;
        }
      }
    }

    .cta {
      padding-top: 40px;
      .btn {
        color: white;
      }
    }
    .pricing {
      padding-top: 40px;
    }
  }

  .details {
    padding-top: 40px;
  }

  .footer {
    background-color: $color-2;
    color: white;
    margin: 0;
    .contact {
      margin-bottom: 0;
    }
    padding: 1rem;
  }
}
